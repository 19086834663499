html,
body {
  margin: 0;
  border: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: 100%;
  font-size: 16px;
}
body {
  color: black;
  background-size: cover;
  font-family: 'Open Sans Condensed', sans-serif;
}

a {
  text-decoration: underline;
}

p {
  font-size: 17px;
  line-height: 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  margin-bottom: 30px;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}
h1 {
  font-size: 58px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 32px;
}

.lang-selector {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.lang-selector input {
  display: none;
}

section {
  position: relative;
  padding: 120px 0 90px;
  background-color: white;
}

@media (max-width: 662px) {
  section {
    padding: 90px 0;
  }
}

section#about {
  padding-bottom: 0;
}

section#about .bio-title {
  text-align: left;
}

section#experiences {
  padding: 30px 0 90px;
}

.homepage section#experiences {
  padding: 30px 0;
}

.homepage section {
  padding: 60px 0;
}

@media (max-width: 992px) {
  .homepage {
    padding-top: 71px;
  }
}

.navbar-container {
  z-index: 10;
}

.navbar {
  min-height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1030;
  background-color: white;
}

.navbar a {
  color: black;
  text-transform: uppercase;
  font-weight: 500;
  z-index: 9;
  letter-spacing: 1px;
}

.navbar a.nav-link.active,
.navbar a.nav-link:hover {
  border: 1px solid black;
}

.navbar a.nav-link {
  height: 38px;
  color: black;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-left: 2px;
}

.navbar-brand {
  text-decoration: none;
  margin-bottom: 0;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar-brand .logo {
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
}

.navbar-brand .subtitle {
  font-size: 14px;
}

@media (min-width: 992px) {
  .home-navbar {
    background-color: transparent;
    overflow: hidden;
  }

  .home-navbar.scrolled {
    background-color: rgba(255, 255, 255, 0.985);
  }

  .home-navbar:not(.scrolled) .home-navbar-backdrop {
    position: absolute;
    inset: 0;
    backdrop-filter: blur(20px);
  }

  .home-navbar a,
  .home-navbar a.nav-link,
  .home-navbar a.nav-link.active,
  .home-navbar a.nav-link:hover,
  .home-navbar .social-links a,
  .lang-selector,
  .lang-selector label {
    color: white;
  }

  .home-navbar.scrolled a,
  .home-navbar.scrolled a.nav-link,
  .home-navbar.scrolled .social-links a,
  .home-navbar.scrolled .lang-selector,
  .home-navbar.scrolled .lang-selector label {
    color: black;
  }

  .home-navbar a.nav-link.active,
  .home-navbar a.nav-link:hover {
    border: 1px solid white;
  }

  .home-navbar.scrolled a.nav-link.active,
  .home-navbar.scrolled a.nav-link:hover {
    border: 1px solid black;
  }

  .home-navbar:not(.scrolled) .navbar-toggler {
    border-color: white;
  }

  .home-navbar:not(.scrolled) .navbar-toggler .navbar-toggler-icon {
    filter: invert(1);
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
}

.content-container {
  display: -ms-flexbox;
  display: flex;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 40px 60px 60px 60px;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.bio-text {
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.social-links-lessons {
  margin-left: -10px;
}

.social-links {
  font-size: 20px;
  line-height: 20px;
}

.social-links a {
  color: black;
  padding: 10px;
  height: initial;
  width: initial;
  display: inline-block;
  text-align: center;
  background: transparent;
}

.social-links a:hover {
  transform: scale(1.1);
  transition: all 0.3s;
}

@media (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
  .content-container {
    padding: 20px;
    text-align: center;
  }
}

.card {
  border: none;
}

.card img {
  transition: all 0.3s;
}
.card img:hover {
  transform: scale(1.03);
}

.footer-wrapper {
  padding: 20px;
}

.footer-wrapper .footer .col-12 {
  border-top: 1px solid black;
}

.footer-wrapper .footer .social-links {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.footer-wrapper .footer .social-links a {
  margin-right: 0;
  width: initial;
  height: initial;
}

.navbar-nav .social-links {
  margin-left: 20px;
  font-size: 18px;
}

.card {
  /* border-radius: 0; */
  transition: all 0.3s;
}

.card img {
  border-radius: 6px;
}

.card img:hover {
  transform: initial;
}

.card:hover {
  transition: all 0.3s;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.card .card-body .card-title {
  margin-bottom: 1rem;
}

.carousel-wrapper .react-multi-carousel-list .react-multiple-carousel__arrow {
  top: calc(4% + 1px) !important;
}

.shop-carousel .react-multiple-carousel__arrow {
  top: initial;
}

.carousel-wrapper {
  min-height: 300px;
}

.carousel-large-wrapper {
  height: 100%;
}

@media (min-width: 992px) {
  .carousel-large-wrapper {
    height: 100vh;
  }
}

.carousel-large-wrapper .carousel-container {
  height: 100%;
}

.carousel-large-wrapper .carousel-container .react-multi-carousel-track {
  height: 100%;
}

.carousel-large-item {
  height: 100%;
}

.carousel-large-item a {
  height: 100%;
}

.carousel-large-item img {
  height: 100%;
}

.lazy-load-image-background {
  width: 100%;
  height: 100%;
}

.lazy-load-image-background img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .lazy-load-image-background img {
    object-fit: cover;
  }
}

/* Prevent social icons from shifting layout */
.social-links a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.loading-placeholder {
  background: #f0f0f0;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
